<template>
  <div class="device-analytics">
    <b-card
      no-body
      class="overflow-hidden"
    >
      <b-container fluid>
        <b-skeleton-wrapper :loading="getLoadedDevice || loader">
          <template #loading>
            <!--Image Section-->
            <b-row class="mt-2">
              <b-col
                lg="6"
                md="6"
                xl="6"
              >
                <b-skeleton
                  type="button"
                  width="150px"
                />
              </b-col>
              <b-col
                lg="1"
                md="1"
                xl="1"
                class="px-0 mx-0 pl-1"
              >
                <b-skeleton
                  height="35px"
                  width="65px"
                  class="pl-2 mx-0"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                lg="6"
                md="6"
                xl="6"
              >
                <b-skeleton-img
                  aspect="1:1"
                  card-image="left"
                />
              </b-col>
              <b-col
                lg="6"
                xl="6"
                md="6"
              >

                <!--First Section-->
                <b-skeleton
                  animation="wave"
                  width="85%"
                />
                <b-skeleton
                  animation="wave"
                  width="55%"
                />
                <b-skeleton
                  animation="wave"
                  width="70%"
                />
                <b-skeleton
                  animation="wave"
                  width="85%"
                />
                <div class="px-1">
                  <hr class="mb-2">
                </div>
                <b-skeleton
                  animation="wave"
                  width="55%"
                />
                <b-skeleton
                  animation="wave"
                  width="70%"
                />
                <b-skeleton
                  animation="wave"
                  width="85%"
                />
                <b-skeleton
                  animation="wave"
                  width="55%"
                />
                <div class="px-1">
                  <hr class="mb-2">
                </div>
                <!--Third Section-->
                <b-skeleton
                  animation="wave"
                  width="70%"
                />
                <b-skeleton
                  animation="wave"
                  width="85%"
                />

                <!--Last Section-->
                <b-skeleton
                  animation="wave"
                  width="45%"
                />
                <b-skeleton
                  animation="wave"
                  width="55%"
                />
                <div class="px-1">
                  <hr class="mb-2">
                </div>
                <b-skeleton
                  animation="wave"
                  width="30%"
                />
                <b-skeleton
                  animation="wave"
                  width="30%"
                />
                <b-skeleton
                  animation="wave"
                  width="30%"
                />
              </b-col>
            </b-row>

          </template>
          <b-row class="pt-2">
            <b-col
              lg="6"
              md="6"
              xl="6"
              sm="4"
              class="device-name"
            >
              <!--DropDown Skeleton-->
              <DeviceDropdown
                v-if="shouldDropdownAppear"
                :user-status="userStatus"
              />
              <span
                v-else
                class="device-info-heading text-primary"
              >
                {{ device.name }}
                <span />
              </span></b-col>
            <!-- Device Name and Edit Button -->
            <b-col
              xl="3"
              lg="3"
              md="3"
              sm="4"
              style="display: inline;"
              class="mt-0 p-0 device-id"
            >
              <b-card-title
                class="m-0 p-0 device--title"
                style="display: inline;"
              >
                <span class="text-primary ml-1">#{{ device.id }}</span>
              </b-card-title>
            </b-col>
            <b-col
              lg="3"
              xl="3"
              md="3"
              sm="4"
              style="display: inline;"
              class="device-monetisation"
            >
              <span class="checkbox">
                <span class="p-0 device-info-heading-1 m-0 ">
                  Monetisation
                </span>
                <b-form-checkbox
                  :checked="device.ssp_opt_in"
                  class="custom-control-primary pt-auto"
                  size="sm"
                  inline
                  switch
                  @change="updateSspOptIn"
                />
                <!-- <b-dropdown
                  variant="link"
                  no-caret
                  class="chart-dropdown float-right"
                  toggle-class="p-0"
                  right
                  @change="updateSSpOptIn"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="18"
                      class="text-body cursor-pointer"
                    />
                  </template>
                  <b-dropdown-item @click="deleteDevice">
                    Delete
                  </b-dropdown-item>
                </b-dropdown> -->
              </span>
            </b-col>
          </b-row>
          <!-- align-h="start" -->
          <!-- Image section-->
          <b-row class="pb-2 pt-0">
            <b-col
              class="pt-2"
              xl="6"
              lg="6"
              md="6"
            >
              <b-carousel
                v-if="!uploadStatus || !device.images.length === 0"
                id="carousel-1"
                v-model="slide"
                :interval="3000"
                :controls="shouldShowControls"
                :indicators="shouldShowControls"
                style="background-color: #F8F8F8; position: relative; width: 95%;"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
              >
                <b-dropdown
                  v-if="device.images.length"
                  variant="link"
                  no-caret
                  class="chart-dropdown"
                  toggle-class="p-0"
                  right
                  style="z-index: 4; float: right;"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="18"
                      class="text-body cursor-pointer"
                    />
                  </template>
                  <b-dropdown-item @click="openDeleteModal">
                    Delete
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="device.images.length !== 5"
                    @click="uploadStatus = !uploadStatus"
                  >
                    Upload Image
                  </b-dropdown-item>
                </b-dropdown>
                <b-carousel-slide
                  v-for="(image, index) in device.images"
                  :key="index"
                >
                  <!-- :img-src="image" -->
                  <template #img>
                    <div class="test-1">
                      <b-img
                        :src="image"
                        alt="image"
                        fluid
                        rounded
                      />
                    </div>
                  </template>
                </b-carousel-slide>
              </b-carousel>
              <AddImage
                v-if="device.images.length === 0 || uploadStatus"
                :spinner="spinner"
                style="z-index:5; position: relative; background-color: rgba(0, 0, 0, 0.5); "
                :images-length="device.images.length"
                @upload-image="imageTransfer"
                @close-overlay="uploadStatus = !uploadStatus"
              />
            </b-col>
            <!--Device Analytics-->
            <b-col
              class="pt-2 text-container"
              xl="6"
              lg="6"
              md="6"
            >
              <b-card-body class="p-0">
                <b-card-text>
                  <!--Location Name-->
                  <span
                    v-if="device.user_has_group.name"
                    class="mb-1 device-info-heading-1 text-primary"
                  >
                    Business Name : <span
                      v-if="device.user_has_group.name"
                      class="device-info-text-1 text-primary"
                    >{{
                      device.user_has_group.name
                    }}</span>
                  </span>
                  <!--Device Contact Information-->
                  <!--Border Line Div-->
                  <div class="px-1">
                    <hr class="mb-2">
                  </div>
                  <span
                    v-if="device.user_has_group.name"
                    class="mb-1 device-info-heading-1"
                  >
                    Categories
                    <span class="float-right">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="primary"
                        size="sm"
                        @click="openViewAndUpdateCategoriesModal"
                      >
                        Manage Restrictions
                      </b-button>
                    </span>
                  </span>
                  <div class="p-1">
                    <hr class="mb-2">
                  </div>
                  <!--Venue Section-->
                  <div>
                    <span class="device-info-heading">Venue Name: </span>
                    <span
                      v-if="device.venue.venue_name"
                      class="device-info-text"
                    >{{ device.venue.venue_name }}</span>
                    <br>
                    <span class="device-info-heading">Venue Type - </span>
                    <span class="device-info-text">{{ device.venue.type? device.venue.type.venue_type_name : null }}</span>
                    <br>
                    <span class="device-info-heading">Venue Email - </span>
                    <span class="device-info-text">{{ device.venue.contact_email }}</span>
                    <br>
                    <span class="device-info-heading">Venue Phone Number - </span>
                    <span class="device-info-text">{{ device.venue.number }}</span>
                    <br>
                    <span class="device-info-heading">Venue Address: </span>
                    <span class="device-info-text">{{ device.venue.address }}</span>
                    <br>
                    <span class="device-info-heading-3">Venue Operating Hours </span>
                    <br>
                    <span class="device-info-heading-1">
                      Venue Start Time: </span>
                    <span class="device-info-text">{{ device.venue.start_time }}</span>
                    <br>
                    <span class="device-info-heading-1">
                      Venue End Time: </span>
                    <span class="device-info-text">{{ device.venue.end_time }}</span>
                    <br>
                    <!--Border Line Div-->
                    <div class="px-1">
                      <hr class="mb-2">
                    </div>
                    <!--Operating Hours-->
                    <span class="device-info-heading-3">Screen Operating Hours </span>
                    <span class="float-right">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="flat-primary"
                        size="sm"
                        class="btn-icon rounded-circle m-0"
                        @click="openOperatingHoursModal"
                      >
                        <feather-icon icon="Edit2Icon" />
                      </b-button>
                    </span>
                    <br>
                    <span class="device-info-heading-1">
                      Start Time: </span>
                    <span class="device-info-text">{{ device.op_start_time }}</span>
                    <br>
                    <span class="device-info-heading-1">
                      End Time: </span>
                    <span class="device-info-text">{{ device.op_end_time }}</span>
                    <br>
                    <!--Venue Impression details-->
                    <span class="device-info-heading">Height: </span>
                    <span class="device-info-text">{{ device.height }}px</span>
                    <span class="ml-4 device-info-heading">Width: </span>
                    <span class="device-info-text">{{ device.width }}px</span>
                    <br>
                    <span class="venue-heading-2">Supports: </span>
                    <b-badge
                      v-if="device.video_support"
                      class="badge ml-2"
                      variant="light-success"
                    >
                      Video
                    </b-badge>
                    <b-badge
                      v-if="device.image_support"
                      class="badge ml-1"
                      variant="light-warning"
                    >
                      Images
                    </b-badge>
                    <span class="float-right">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="flat-primary"
                        size="sm"
                        class="btn-icon rounded-circle m-0"
                        @click="openDevicePropsModal"
                      >
                        <feather-icon icon="Edit2Icon" />
                      </b-button>
                    </span>
                  </div>
                  <div class="px-1">
                    <hr class="mb-2">
                  </div>
                  <b-col
                    cols="12"
                  >
                    <div>
                      <div class="device-info-heading-3 d-flex justify-content-between">
                        <span class="pt-1">
                          Audience Profile
                        </span>
                        <span v-if="shouldEditAudienceProfileShow">
                          <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="flat-primary"
                            size="sm"
                            class="btn-icon rounded-circle"
                            @click="openEditAudienceProfileModal"
                          >
                            <feather-icon icon="Edit2Icon" />
                          </b-button>
                        </span>
                      </div>
                      <div
                        class="tab-content-wrapper mt-1"
                      >
                        <div
                          v-html="device.audience_profile ? device.audience_profile : 'Nothing to Show'"
                        />
                      </div>
                    </div>
                  </b-col>
                  <div class="px-1">
                    <hr class="mb-2">
                  </div>
                  <div>
                    <span class="device-info-heading">Active Status: </span>
                    <span>
                      <b-badge
                        class="badge"
                        :variant="device.status === DeviceStatus.ACTIVE ? 'light-success' : 'light-danger'"
                      >{{
                        device.status === DeviceStatus.ACTIVE ? DeviceStatus.ACTIVE : DeviceStatus.INACTIVE }}
                      </b-badge>
                    </span>
                  </div>
                  <div>
                    <span class="device-info-heading-1">Latitude: </span>
                    <span class="device-heading-text">{{ device.latitude }}</span>
                    <span class="device-info-heading-1 ml-2">Longitude: </span>
                    <span class="device-heading-text">{{ device.longitude }}</span>
                    <span class="float-right">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="flat-primary"
                        size="sm"
                        class="btn-icon rounded-circle m-0"
                        @click="openUpdateLocationModal"
                      >
                        <feather-icon icon="Edit2Icon" />
                      </b-button>
                    </span>
                  </div>
                </b-card-text>
              </b-card-body>
              <div>
                <span class="device-info-heading-1">Last Updated: </span>
                <span class="device-info-text">{{ lastUpdatedDate }}</span>
              </div>
              <div class="px-1">
                <hr class="mb-2">
              </div>
              <span class="device-info-heading-3 mb-2">Screen Impressions Info </span>
              <b-row
                class=" pt-1"
              >
                <b-col
                  align-self="start"
                  cols="8"
                  order="12"
                >
                  <div class="d-flex justify-content-between align-items-center">
                    <h5
                      style="font-weight: bold; display: inline;"
                      class="text-primary"
                    >
                      Impressions : {{ parseInt(device.impressions) }}
                    </h5>
                    <div
                      v-if="userRole === AccountTypes.ADMIN"
                      class="paddingClass pr-2"
                    >
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="flat-primary"
                        size="sm"
                        class="btn-icon rounded-circle"
                        @click="openImpressionUpdateModal"
                      >
                        <feather-icon icon="Edit2Icon" />
                      </b-button>
                    </div>
                  </div>
                </b-col>
                <b-col
                  align-self="end"
                  cols="4"
                  order="12"
                >
                  <div class="d-flex justify-content-between align-items-center">
                    <span
                      style="font-weight: bold; display: inline;"
                      class="text-primary"
                    >
                      CPM : ${{ parseInt(device.cpm) }}
                    </span>
                    <div
                      v-if="userRole === AccountTypes.ADMIN"
                      class="paddingClass"
                    >
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="flat-primary"
                        size="sm"
                        class="btn-icon rounded-circle"
                        @click="openEditModal"
                      >
                        <feather-icon icon="Edit2Icon" />
                      </b-button>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-skeleton-wrapper>
      </b-container>
    </b-card>
    <b-card>
      <b-row>
        <b-col
          lg="6"
          md="6"
        >
          <b-skeleton-img
            v-if="loader"
            no-aspect
            height="300px"
          />
          <CardEarningReports
            v-else
            :stats-data="device ? device.earningDetails : null"
            :date-sequence="dateSequence"
            :earning-sequence="earningSequence"
          />
        </b-col>
        <b-col
          lg="6"
          md="6"
        >
          <b-skeleton-img
            v-if="loader"
            no-aspect
            height="300px"
          />
          <ExternalAdsTracker
            v-else
            :stats-data="device ? device.campaignTracker : null"
          />
        </b-col>
      </b-row>
    </b-card>
    <DeleteImage
      ref="delete-image-modal"
      :images="device.images"
      @delete-image="deleteImage"
    />
    <UpdateDeviceLocation
      ref="update-location-modal"
      :data="deviceDataLocation"
      @refresh-data="callRefreshData"
    />
    <UpdateDeviceOperatingHours
      ref="update-operating-hours"
      :data="deviceDataLocation"
      @refresh-data="callRefreshData"
    />
    <ViewAndUpdateCategories
      ref="view-update-categories"
      @refresh-data="callRefreshData"
    />
    <UpdateDeviceInfo
      ref="update-device-info"
      :data="deviceDataLocation"
      @refresh-data="callRefreshData"
    />
    <UpdateDeviceProps
      ref="update-device-props"
      :data="deviceDataLocation"
      @refresh-data="callRefreshData"
    />
    <EditDeviceCPM
      ref="edit-device"
      :data="deviceDataLocation"
      @refresh-data="callRefreshData"
    />
    <EditDeviceImpression
      ref="edit-device-impressions"
      @refresh-data="callRefreshData"
    />
    <CardEditAudienceProfile
      ref="edit-device-audience-profile"
      type="Device"
      :device-id="$route.params.deviceId"
      @refresh-data="refreshData"
    />
  </div>
</template>
<script>
import {
  BCard, BBadge, BDropdown, BDropdownItem, BCardImg, BCardTitle, BCardHeader, BCardBody, BMedia,
  BMediaAside, BMediaBody, BLink, BImg, BButton, BAvatar, BSkeletonTable, BProgress, BProgressBar,
  BContainer, BRow, BCol, BOverlay,
  BSkeleton, BSkeletonWrapper, BSkeletonImg, BFormCheckbox, BCardText, BCarousel, BCarouselSlide,
} from 'bootstrap-vue'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import Vue from 'vue'
import DeleteImage from '../mediastorage/DeleteImage.vue'
import AccountTypes from '@/common/enums/accountTypeEnum'
import DeviceDropdown from './DeviceDropdown.vue'
import AddImage from '../mediastorage/AddImage.vue'
import { showToast } from '@/common/global/functions'
import UpdateDeviceLocation from './UpdateDeviceLocation.vue'
import UpdateDeviceOperatingHours from './UpdateDeviceOperatingHours.vue'
import ViewAndUpdateCategories from './ViewAndUpdateCategories.vue'
import EditDeviceCPM from './EditDeviceCPM.vue'
import EditDeviceImpression from './EditDeviceImpression.vue'
import DeviceStatus from '@/common/enums/deviceStatusEnum'
import UpdateDeviceInfo from './UpdateDeviceInfo.vue'
import UpdateDeviceProps from './UpdateDeviceProps.vue'
import CardEarningReports from '@/components/common/CardEarningReports.vue'
import ExternalAdsTracker from '@/components/account-setting/ExternalAdsTracker.vue'
import CardEditAudienceProfile from '../common/CardEditAudienceProfile.vue'
import MemberPermissions from '@/common/enums/memberPermissionsEnum'

Vue.directive('ripple', Ripple)
export default {
  /* eslint-disable */
  components: {
    EditDeviceCPM,
    EditDeviceImpression,
    DeleteImage,
    BOverlay,
    AddImage,
    BDropdown,
    BDropdownItem,
    BCard,
    BBadge,
    BContainer,
    BRow,
    BCol,
    BProgress,
    BProgressBar,
    BCardTitle,
    BCardHeader,
    BCardText,
    BCardBody,
    BCardImg,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BSkeleton,
    BSkeletonWrapper,
    BButton,
    BAvatar,
    BSkeletonTable,
    DeviceDropdown,
    BSkeletonImg,
    BFormCheckbox,
    BCarousel,
    BCarouselSlide,
    UpdateDeviceLocation,
    UpdateDeviceOperatingHours,
    UpdateDeviceInfo,
    UpdateDeviceProps,
    ViewAndUpdateCategories,
    CardEarningReports,
    ExternalAdsTracker,
    CardEditAudienceProfile,
  },
  directives: {
    Ripple,
  },
  /* eslint-enable */
  props: {
    deviceObject: {
      type: Object,
      required: true,
    },
    getLoadedDevice: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['load-device', 'load-data', 'referesh-data'],
  data() {
    return {
      DeviceStatus,
      activeStatus: false,
      slide: 0,
      sliding: null,
      contentHeight: 0,
      spinner: false,
      uploadStatus: false,
      showDeleteModal: false,
      imageIndex: 0,
      imagesFile: [],
      deviceDataLocation: {},
      AccountTypes,
      loader: false,
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters['user/getUser']
    },
    userRole() {
      return this.$store.getters['user/getUserRole']
    },
    lastUpdatedDate() {
      return moment(this.device.last_updated_date).utc().format('ddd, MMM Do YYYY, HH:mm:ss')
    },
    userStatus() {
      return this.currentUser.user_has_role[0].role.role === 'admin' ? 'admin' : 'user'
    },
    sspOptIn() {
      return this.device.ssp_opt_in
    },
    dateSequence() {
      return this.device?.earningDetails ? this.device.earningDetails.last7DaysEarningsArray.map(item => item.date) : []
    },
    earningSequence() {
      return this.device?.earningDetails ? this.device.earningDetails.last7DaysEarningsArray.map(item => item.earning) : []
    },
    device: {
      get() {
        return { ...this.deviceObject }
      },
      set(newDevice) {
        this.deviceDataLocation = { ...newDevice }
      },
    },
    shouldShowControls() {
      return this.device.images.length !== 1
    },
    shouldDropdownAppear() {
      if (this.$route.meta.breadcrumb[0].to === '/nonconfig' || this.$route.meta.breadcrumb[0].to === '/screenList') {
        return false
      }
      if (this.$route.meta.breadcrumb[0].text === 'Screen Details' || this.$route.meta.breadcrumb[0].text === 'Venues & Screens' || this.$route.name === 'user-single-screen-details') {
        return false
      }
      return true
    },
    isAgency() {
      return this.$store.getters['user/getSelectedGroupMemberPermissions'].includes(MemberPermissions.AGENCY)
    },
    shouldEditAudienceProfileShow() {
      if (!this.isAgency) {
        return this.$route.name === 'single-venue-screen' || this.$route.name === 'dashboard-single-device-user' || this.$route.name === 'user-single-screen-details' || this.$route.name === 'user-venue-single-screen-details'
      }
      return false
    },
  },
  mounted() {
    this.hideCarousel()
    // Calculate the height of the content and set it to contentHeight
    this.$nextTick(() => {
      const container = this.$el.querySelector('.text-container')
      if (container) {
        this.contentHeight = container.clientHeight - 20
      }
    })
  },
  /* eslint-disable */
  methods: {
    callRefreshData() {
      this.$emit("referesh-data")
      this.device = { ...this.deviceObject }
    },
    onSlideStart(slide) {
      this.sliding = true
    },
    hideCarousel() {
      if (this.device.images.length === 0) {
        this.uploadStatus = true
      } else {
        this.uploadStatus = false
      }
    },
    async deleteDevice() {
      this.$swal.fire({
        title: 'Are you sure you want to delete this screen?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        cancelButtonText: ' Cancel',
        reverseButtons: true,
        width: '650px',
      }).then(async result => {
        if (result.isConfirmed) {
          this.loader = true;
      try {
        if(this.userRole === this.AccountTypes.USER){
        const success = await this.$store.dispatch("devices/deleteDevice", { macAddress: this.device.mac_address })
        if (success) {
          showToast('Device Deleted', 'Device deleted successfully', 'success')
          this.$router.back()
        }
      } else {
        const success = await this.$store.dispatch("devices/deleteDeviceByAdmin", { macAddress: this.device.mac_address })
        if (success) {
          showToast('Device Deleted', 'Device deleted successfully', 'success')
          this.$router.back()
      }
    }
      } catch (e) {
        const errorMessage = e.response && e.response.data && e.response.data.message
    ? e.response.data.message
    : e.message;
          showToast('Device Deletion', errorMessage, 'danger')
      } finally{
        this.loader = false;
      }
    }
  })
    },
    onSlideEnd(slide) {
      this.sliding = false
    },
    /* eslint-enable */
    updateSspOptIn(event) {
      this.$emit('load-device', event)
    },
    async deleteImage(value) {
      try {
        const success = await this.$store.dispatch('devices/deleteImage', {
          url: value.item,
          userId: this.device.user_has_group.id,
          deviceMac: this.device.mac_address,
        })
        if (success) {
          showToast('Successful', 'Image deleted successfully', 'success')
          this.openDeleteModal()
          this.$emit('load-data')
        }
      } catch (error) {
        // Catch the error thrown by the Vuex action
        const errorMessage = error.message || 'Unknown error'
        showToast('Failed', errorMessage, 'danger')
      }
    },
    openUpdateLocationModal() {
      this.deviceDataLocation = { ...this.device }
      this.$refs['update-location-modal'].showLocationModal()
    },
    openOperatingHoursModal() {
      this.deviceDataLocation = { ...this.device }
      this.$refs['update-operating-hours'].showOpTimeModal()
    },
    openViewAndUpdateCategoriesModal() {
      this.$refs['view-update-categories'].showOpTimeModal(this.device)
    },
    openDeviceInfoModal() {
      this.deviceDataLocation = { ...this.device }
      this.$refs['update-device-info'].showDeviceModal()
    },
    openDevicePropsModal() {
      this.deviceDataLocation = { ...this.device }
      this.$refs['update-device-props'].showDevicePropsModal()
    },
    openEditModal() {
      this.deviceDataLocation = { ...this.device }
      this.$refs['edit-device'].showCpmModal()
    },
    openImpressionUpdateModal() {
      this.deviceDataLocation = { ...this.device }
      this.$refs['edit-device-impressions'].showImpressionModal(this.deviceDataLocation)
    },
    openEditAudienceProfileModal() {
      this.$refs['edit-device-audience-profile'].showCpmModal(this.device.audience_profile)
    },
    openDeleteModal() {
      this.$refs['delete-image-modal'].show()
    },
    imageTransfer(images) {
      this.imageIndex = 0
      this.imagesFile = images
      this.imageFile()
      this.spinner = true
    },
    imageFile() {
      if (this.imageIndex >= this.imagesFile.length) {
        showToast('Images uploaded', 'Images have been uploaded', 'success')
        this.spinner = false
        this.uploadStatus = false
        this.$emit('load-data')
      } else {
        this.uploadImage(this.imagesFile[this.imageIndex])
        this.imageIndex += 1
      }
    },
    async uploadImage(fileData) {
      try {
        const results = await this.$store.dispatch('devices/uploadImage', {
          payload: fileData,
          userId: this.device.user_has_group.id,
          deviceMac: this.device.mac_address,
        })
        if (results) {
          showToast('Image Uploaded', 'Image uploaded successfully', 'success')
          this.imageFile()
        } else {
          // Handle the case where at least one upload failed
          showToast('Partial Upload', 'Some images failed to upload', 'warning')
          this.imageFile()
        }
      } catch (error) {
        // Catch the error thrown by the Vuex action
        const errorMessage = error.message || 'Unknown error'
        showToast('Failed', errorMessage, 'danger')
        this.imageFile()
      }
    },
    refreshData() {
      window.location.reload()
    },
    // pushToThePath() {
    //   this.$router.back()
    // if (this.userStatus === this.AccountTypes.ADMIN) {
    //   if (this.$route.meta.breadcrumb[0].to === '/nonconfig') {
    //     return '/nonconfig'
    //   } if (this.$route.meta.breadcrumb[0].to === '/screenList') {
    //     return '/screenList'
    //   }
    //   return `/users/${device.user_has_group.id}/${device.venue.id}/user-devices-group`
    // }
    // return ''
    // },
  },
}
</script>
<style scoped lang="scss">
.device-analytics {
  .image-div {
    object-fit: cover;
    width: 100%;
    // height: 100% !important;
  }
  .test-1 {
    width: 100%;
    height: calc(100vmin - 100px);
    object-fit: fill;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
  }
  .device-info-heading {
    // padding-left: 2px;
    font-size: 14px;
    line-height: 21px;
    color: #6e6b7b;
    font-family: "Montserrat";
    font-weight: 700;
    font-style: normal;
  }
  //Second Heading for device
  .device-info-heading-1 {
    font-size: 14px;
    line-height: 0px;
    color: #6e6b7b;
    font-family: "Montserrat";
    font-weight: 900;
    font-style: normal;
  }
  .device-info-heading-2 {
    font-size: 20px;
    line-height: 0px;
    color: #6e6b7b;
    font-family: "Montserrat";
    font-weight: 900;
    font-style: normal;
  }
  .tab-content-wrapper {
    padding: 1.5rem;
    border-radius: 0 0 6px 6px;
    background: #f8f8f8;
    min-height: 150px !important;
  }
  .device-info-heading-3 {
    font-size: 16px;
    line-height: 0px;
    color: #6e6b7b;
    font-family: "Montserrat";
    font-weight: 900;
    font-style: normal;
    margin-bottom: 2px !important;
  }
  .checkbox {
    // float: right;
    text-align: right !important;
    display: inline !important;
  }
  //Device Text Typography
  .device-info-text {
    // padding-left: 2px;
    font-size: 12px;
    line-height: 21px;
    color: #6e6b7b;
    font-family: "Montserrat";
    font-weight: 400;
  }
  .device-info-text-1 {
    // padding-left: 2px;
    font-size: 16px;
    line-height: 21px;
    color: #6e6b7b;
    font-family: "Montserrat";
    font-weight: 400;
  }
  .group-name {
    font-size: 14px;
    line-height: 21px;
    color: #6e6b7b;
    font-family: "Montserrat";
    font-weight: 600;
  }
  .badge {
    border-radius: 25px;
    padding-inline: .625rem;
  }
  .edit-icon {
    padding-top: 0px !important;
    margin-top: 0px !important;
  }
}
.custom-control-primary {
  padding-top: 8px;
}
.custom-control-primary ::v-deep label.custom-control-label {
  padding: 0px !important;
}
.device--title {
  padding-top: 4px !important;
}
@media (max-width: 576px) {
.device-name{
  width: 25%;
}
.device-id{
  width: 20%;
}
}
@media (max-width: 460px) {
.device-name{
  width: 50%;
}
.device-id{
  width: 50%;
  display: flex !important;
  justify-content: flex-end;
  padding-right: 10px !important;
  padding-top: 0px !important;
}
.device--title{
  padding-top: 0px !important;
}
.device-monetisation{
  display: flex !important;
  width: 100%;
  justify-content: flex-end;
  padding-right: 10px;
}
.text-body{
  margin-top: 5px;
}
.paddingClass{
  margin-bottom: 10px;
}
}
</style>
